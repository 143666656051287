@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
    margin: 0;
    border: none;
    outline: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Poppins', sans-serif;
}
body{
    height: 100vh;
    overflow-y: hidden;
}
.chat-div {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    bottom: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 450px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1));
    z-index: 500;
}

.chat-box {
    width: 100%;
    height: calc(100vh - 60px);
    height: calc(var(--vh, 1vh) * 100 - 60px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

@media screen and (max-width: 1100px) {
    .chat-div {
        width: 400px;
    }
}

.chat-box::-webkit-scrollbar {
    width: 7px;
}

.chat-box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.chat-box::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.msg-box {
    border: 1px solid rgba(255, 255, 255, 0.7);
    height: 50px;
    display: flex;
    border-radius: 25px;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(50, 49, 49, 0.678);
}

#mic {
    height: 40px;
    width: 40px;
    margin-left: 10px;
    border-radius: 20px;
    transition: all ease 0.3s;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

img {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    pointer-events: none;
    user-select: none;
}

#mic:active {
    transform: scale(1.1);
    background: linear-gradient(-45deg, #35C3F3 0%, #8b9fe8 20%, #e681d8 39%, #ffa9a4 76%, #FED2CE 100%);
}

.msg-box input {
    width: calc(75%);
    background-color: transparent;
    font-size: 17px;
    color: rgb(236, 234, 234);
}

.msg-box input::placeholder {
    color: rgb(209, 209, 209);
}

#send {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    border-radius: 20px;

}

#send:hover {
    background-color: #35a4f3;
}

.msgbtn {
    background: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.msgbtn img {
    width: 22px;
    height: 22px;
}

.chat-box p {
    width: fit-content;
    font-size: 16px;
    padding: 10px 15px;
    color: white;
    border-radius: 10px;
    background-color: rgb(31, 31, 31);
    margin-block: 5px;
    max-width: 350px;
    margin-left: 5px;
}

.chat-box p.me {
    margin-left: auto;
    margin-right: 10px;
    background-color: #35a4f3;
}

.chat-box p .time {
    font-size: 10px;
    color: rgb(143, 143, 143);
    text-align: end;
}

.Toastify__progress-bar {
    height: 4px;
}

@media screen and (max-width: 750px) {
    .chat-div {
        width: 350px;
    }
}

@media screen and (max-width: 500px) {

    .chat-div {
        width: 100%;
        height: 350px;
        bottom: 0;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1));
    }

    .chat-box {
        height: calc(100vh - 70px);
        height: calc(var(--vh, 1vh) * 100 - 70px);
    }

    .msg-box {
        margin-bottom: 10px;
        margin-left: 7px;
        margin-right: 7px;
    }

    .chat-box p {
        font-size: 15px;
    }

    .msg-box input {
        font-size: 15px;
    }
}

.about{
    position: absolute;
    left: 15px;
    top: 15px;
    display: flex;
    z-index: 3;
    padding: 7px;
    background-color: #222;
    border-radius: 5px;
    cursor: pointer;
}
.about img{
    width: 22px;
    height: 22px;
}
.modal{
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    width: 90%;
    max-width: 400px;
    padding: 10px;
    text-align: center;
    background: rgba(29, 28, 28, 0.7);
    border-radius: 5px;
    border: 1px solid white;
    color: white;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    transition: all 1s ease-out;
    animation: ease-out 1s;
}
.modal h1{
    margin-top: 20px;
}
.modal a{
    color: #35a4f3;
    text-decoration: none;
}